/* eslint-disable */

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MaterialReactTable } from 'material-react-table';
import { darken } from "@mui/material";
import Papa from 'papaparse';
import { Modal, Button } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect, useState } from "react";
import axios from "axios";
import formatDate from "utils/formatDate";


function Tables() {
  // const { columns, rows, pagination } = authorsTableData();

  const accesstoken = sessionStorage.getItem('accessToken');
  const [listing, setListing] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [count, setCount] = useState(0)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState("");
  const [search, setSearch] = useState('')

  const columns = [
   
    {
      header: "Suffix",
      accessorKey: "suffix",
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "left",
      },
      size: 120
    },
    {
      header: "First Name",
      accessorKey: "first_name",
      enableEditing: false,
    },
    {
      header: "Last Name",
      accessorKey: "last_name",
      enableEditing: false,
    },
   
    {
      header: "Company",
      accessorKey: "company",
      enableEditing: false,
    },
    {
      header: "Position",
      accessorKey: "position",
      enableEditing: false,
    },
    {
      header: "Email",
      // accessorKey: "email",
      overflowX: "hidden",
      enableEditing: false,
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center" style={{'overflow': "hidden"}}>
          {row.original.email}
        </div>
      ),
      size: 300,
    },
    {
      header: "Phone",
      accessorKey: "phone_number",
      enableEditing: false,
    },
    {
      header: "Received On",
      accessorKey: "created_date",
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Message",
      enableEditing: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center ">
          <button
            className="btn btn-primary text-light table-action-icon "
            onClick={() => {
              setSelectedMessage(row.original.message);
              setShowDetailModal(true);
            }}
            style={{
              backgroundColor: "#008C95",
              color: "#fff",
              padding: "4px 10px",
              border: "none",
              borderRadius: "5px"
            }}
          >
            Message
            {/* <i className="fa fa-envelope"></i> */}
          </button>
        </div>
      ),
    },
  ];

  const exportCSV1 = () => {
    const csvData = listing.map(row => ({
      Suffix: row.suffix,
      "First Name": row.last_name,
      "Last Name": row.last_name,
      Email: row.email,
      Company: row.company,
      Position: row.position,
      Phone: row.country_code + row.phone_number,
      Message: row.message,
      "Request Date": formatDate(row.created_at),
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "invitations.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportCSV = async () => {
    try {

      let IP = "http://192.168.100.42:8001"

      if (process.env.NODE_ENV == "production"){
        IP = "https://api.saudibanksjointreception.com"
      }

      const response = await fetch(`${IP}/invitations/download`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accesstoken}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to download CSV');
      }
  
      // Parse the response as a Blob
      const responseData = await response.json();
  
      // Convert the data to CSV format
      const csvData = responseData.map(row => ({
        Suffix: row.suffix,
        "First Name": row.first_name,
        "Last Name": row.last_name,
        Email: row.email,
        Company: row.company,
        Position: row.position,
        Phone: row.country_code + row.phone_number,
        Message: row.message,
        "Request Date": formatDate(row.created_at),
      }));
  
      // Custom CSV headers
      const headers = ['Suffix', 'First Name', 'Last Name', 'Email', 'Company', 'Position', 'Phone', 'Message', 'Request Date'];
  
      // Add headers to the CSV file
      const csv = Papa.unparse({
        fields: headers,
        data: csvData
      });
  
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute('download', 'Invitation Requests.csv');
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };
  

  const getInvitationListing = async () => {

    let IP = "http://192.168.100.42:8001"

    if (process.env.NODE_ENV == "production"){
      IP = "https://api.saudibanksjointreception.com"
    }

    const url = `${IP}/invitations/list?page=${pagination.pageIndex + 1}&size=${pagination.pageSize ?? 10}&search=${search ? search : ""}`;
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accesstoken}` },
      });

      if (response.status === 200) {
        const updatedData = response?.data?.results.map((row) => {
          return {
            ...row,
            created_date: formatDate(row.created_at)
          }
        })
        setListing(updatedData)
        setCount(response?.data?.count)
        // console.log('response:', response);
      }
    } catch (error) {
      console.error('Error during login:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (search != "" && search) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
    if (pagination.pageIndex == 0) {
      getInvitationListing();
    }
  }, [search]);

  useEffect(() => {
    getInvitationListing();
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid>
          <Grid>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Invitation Requests
                </MDTypography>
              </MDBox>
              <MaterialReactTable
               
                data={listing}
                columns={columns}
                rowCount={count}
                initialState={pagination}
                state={{
                  // isLoading: interviewsLoading,
                  pagination: pagination,
                  globalFilter: search,
                }}
                enableRowNumbers
                rowNumberMode="static"
                manualPagination
                onPaginationChange={setPagination}
                manualFiltering //turn off client-side filtering
                onGlobalFilterChange={setSearch} //hoist internal global state to your state
                getRowId={(row) => row?.original?.id}
                enableEditing={false}
                layoutMode="grid"
                enableColumnFilters={false}
                enableColumnActions={false}
                enableDensityToggle={false}
                enableFilterMatchHighlighting={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                muiTableBodyProps={{
                  sx: (theme) => ({
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: darken(theme.palette.background.default, 0.05),
                    },
                  }),
                }}
                renderTopToolbarCustomActions={() => (
                  <div className="d-flex">
                    <button onClick={exportCSV} className="btn btn-primary export-csv ml-3" style={{
                      backgroundColor: "#008C95",
                      color: "#fff",
                      padding: "4px 10px",
                      border: "none",
                      borderRadius: "5px"
                    }}>
                      Export CSV
                    </button>
                  </div>
                )}
                
              />
            
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <div style={{ padding: '20px', backgroundColor: '#fff', margin: '10% auto', width: '400px', borderRadius: '8px' }}>
          <h2 id="message-modal-title">Message Details</h2>
          <p id="message-modal-description">{selectedMessage}</p>
          <Button onClick={() => setShowDetailModal(false)} style={{ backgroundColor: '#008C95', color: '#fff', marginTop: '20px' }}>Close</Button>
        </div>
      </Modal>
    </DashboardLayout>
  );
}

export default Tables;
