/* eslint-disable */
import React from "react";
import { Navigate } from "react-router-dom";

// AuthRoute component to redirect to dashboard if token exists
const AuthRoute = ({ children }) => {
  const accessToken = sessionStorage.getItem("accessToken");

  // If the user is already authenticated, redirect to the dashboard
  return accessToken ? <Navigate to="/invitations" /> : children;
};

export default AuthRoute;