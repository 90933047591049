/* eslint-disable */
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import PrivateRoute from "layouts/PrivateRoutes";
import AuthRoute from "layouts/AuthRoute";

const routes = [
  {
    route: "/",
    component: (
      <PrivateRoute>
        <Tables />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Invitations",
    key: "invitations",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/invitations",
    component: (
      <PrivateRoute>
        <Tables />
      </PrivateRoute>
    ),
  },

  {
    route: "/sign-in",
    component: (
      <AuthRoute>
        <SignIn />
      </AuthRoute>
    ),
  },
];

export default routes;
