/* eslint-disable */
import React from "react";
import { Navigate } from "react-router-dom";

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const accessToken = sessionStorage.getItem("accessToken");
  return accessToken ? children : <Navigate to="/sign-in" />;
};

export default PrivateRoute;