/* eslint-disable */

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CircularProgress from '@mui/material/CircularProgress';
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage1 from "assets/images/bg-sign-in-basic.jpeg";
import bgImage2 from "assets/images/slider1.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Basic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    let IP = "http://192.168.100.42:8001"

    if (process.env.NODE_ENV == "production"){
      IP = "https://api.saudibanksjointreception.com"
    }

    const url = `${IP}/accounts/login`;

    if (email == "" || password == "") {
      return
    }
    setIsLoading(true)
    const body = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(url, body);
      // console.log('Login successful:', response.data);
      if (response.data.status === true) {
        const { refresh, access } = response.data;

        // Save tokens to sessionStorage
        sessionStorage.setItem('refreshToken', refresh);
        sessionStorage.setItem('accessToken', access);
        navigate('/invitations')
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error during login:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) { navigate('/invitations') }
  }, [])


  return (
    <BasicLayout image={bgImage2}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Admin Sign-in
          </MDTypography>
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
            Saudi Banks Joint Reception
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {/* {email == "" && <small className="text-danger">Enter email</small>} */}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {/* {password == "" && <small className="text-danger">Enter password</small>} */}
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton className="d-flex align-items-center" disabled={isLoading} variant="gradient" color="info" fullWidth onClick={() => handleSubmit()}>
                Sign in{isLoading && <CircularProgress style={{ color: "#000", marginLeft: "5px", width: "20px", height: "20px  " }} />}
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
